import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageService } from '../pages.service';
import { PageData } from '../pages.model';
import { CalculationsService } from '../shared/calculations/calculations.service';
import { FormDataService } from '../shared/form-data/form-data.service';
import { FormData } from '../shared/form-data/form-data.model';
import { ProjResults } from '../shared/calculations/proj-results.type';
import { ValidationService } from '../shared/form-validations/validation.service';

// import { ChartModule } from 'angular2-highcharts';
import * as Highcharts from "highcharts";
import { CONSTANTS } from '../shared/calculations/calc-constants';
import { SummaryItem } from './summary-item/summary-item.type';
// import { ValueConverter } from '@angular/compiler/src/render3/view/template';

import { ReportDataService } from '../../shared/models/reportdata.service';
import { ReportData } from '../../shared/models/reportdata.model';

declare var Slider: any;

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit, OnDestroy {

    // page
    pageData: PageData;
    reportData: ReportData;

    // chart
    options: any;
    chart: any;
    Highcharts: typeof Highcharts = Highcharts; // required
    updateFlag: boolean;
    // form
    formSummary: FormGroup;
    formData: FormData;
    formDefaults: FormData;
    formErrors: any;
    validationMessages: any;
    cumulativeTaxSavings: number;

    // projections
    projResults: ProjResults;
    altResults: ProjResults;
    projRetBalance: number;
    altRetBalance: number;
    projSeriesVisible = true;
    altSeriesVisible = true;

    // sliders
    sliderHSAContribAlt: any;
    sliderHSASpendAlt: any;
    hsaMaxContrib: number;
    hsaMaxSpend: number;
    hsaMinContrib: number;
    hsaMinSpend: number;
    contribAtMax: boolean;
    spendAtMin: boolean;
    showAlternate: boolean;
    sliderROR: any;
    
    stateTax: number;
    state: string;

    // variables
    singleCoverage: boolean;
    familyCoverage: boolean;
    retirementNeed: number;

    retNeedFamily: number;
    retNeedSingle: number;

    // summary values
    summaryItems: Array<SummaryItem>;
    assumptionItems: Array<SummaryItem>;

    hsaContrib: number;
    retirementAge: number;
    taxTotal: number;
    ror: number;
    hsaUsage: string;
    hsaOOP: number;
    taxSavings: number;

    formSubscription: any;

    currentAgeOptions: Array<number> = [];
    retireAgeOptions: Array<number> = [];

    fedTaxOptions: Array<number> = [];
    // stateTaxOptions: Array<number> = [];
    stateTaxOptions:Object;

    constants: any;

    /**
     * Creates an instance of SummaryComponent.
     * @param {PageService} pageService
     * @param {CalculationsService} calculationService
     * @param {FormBuilder} formBuilder
     * @param {FormDataService} formDataService
     * @param {ValidationService} validationService
     * @param {CurrencyPipe} currencyPipe
     *
     * @memberOf SummaryComponent
     */
    constructor(private pageService: PageService,
        private reportDataService: ReportDataService,
        private calculationService: CalculationsService,
        private formBuilder: FormBuilder,
        private formDataService: FormDataService,
        private validationService: ValidationService,
        private router: Router,
        private currencyPipe: CurrencyPipe) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf SummaryComponent
     */
    ngOnInit() {
        this.initPageData();
        this.initFormData();
       

        this.buildCurrentAgeOptions();
        this.buildRetirementAgeOptions(this.validationService.minRetAge);
        this.buildFedTaxOptions();
        this.buildStateTaxOptions();
        this.updateFlag = false;
        this.setVariables();
        this.initSliders();
        this.initYourSummary();

        this.constants = CONSTANTS;

        this.stateTax = this.formDataService.getValue('stateTax');
        this.state = this.formDataService.getValue('state');

        this.calculationService.calcProjResults();
        this.projResults = this.calculationService.projResults;
        this.projRetBalance = this.projResults.retirementBalance;

        // alternate projection results
        this.calculationService.calcAltProjResults();
        this.altResults = this.calculationService.altProjResults;
        this.altRetBalance = this.altResults.retirementBalance;

        this.taxSavings = this.calculationService.taxSavings;
        this.cumulativeTaxSavings = this.calculationService.taxSavings  * this.calculationService.projResults.year.length;;
        //
        this.initChartData();
        this.setPDFData();
    }


        /**
     * Calculate alternate results.
     * Based on alternate contrib/spend inputs.
     *
     * @memberOf SummaryComponent
     */
    recalculateChart() {

            this.calculationService.calcProjResults();
            this.projResults = this.calculationService.projResults;
            this.projRetBalance = this.projResults.retirementBalance;

            // alternate projection results
            this.calculationService.calcAltProjResults();
            this.altResults = this.calculationService.altProjResults;
            this.altRetBalance = this.altResults.retirementBalance;

            this.taxSavings = this.calculationService.taxSavings;
            this.cumulativeTaxSavings = this.calculationService.taxSavings  * this.calculationService.projResults.year.length;;

            this.initChartData();
            this.setPDFData();
            
    }
    

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf SummaryComponent
     */
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }

    /**
     * Inits page specific data and sends this data to the page service.
     *
     * @memberOf SummaryComponent
     */
    initPageData() {
        this.pageData = {
            currentPage: 'summary',
            nextPage: 'results',
            prevPage: 'step3',
            stepNum: 4,
            progress: 0,
            firstPage: false,
            lastPage: false,
            showProgress: false,
            stepTitle: 'Summary and planning',
            addPrint: true,
            showFootnote: true,
            stepIcon: ''
        };
        this.pageService.initPage(this.pageData);
    }




    initReportData() {
        this.reportData = {
            rptIntro1: '',
            rptIntro2: '',
            summaryFootnote: '',
            client_name: '',
            advisor_name: '',
            advisor_company: '',
            advisor_phone: '',
            advisor_email: '',
            projRetBalance: '',
            altRetBalance: '',
            hsaContrib: '',
            hsaOOP: '',
            hsaSpendAlt: '',
            hsaContribAlt: '',
            taxSavings: '',
            cumulativeTaxSavings: '',
            retirementAge: '',
            fedTax: '',
            stateTax: '',
            ror: ''
        };
        this.reportDataService.initReport(this.reportData);
    }


    /**
     * Save Highcharts chart instance.
     *
     * @param {any} chartInstance
     *
     * @memberOf SummaryComponent
     */
    saveChartInstance(chartInstance) {
        this.chart = chartInstance;
    }
    ngAfterViewInit() {
        this.setPDFData();
    }
    setPDFData() {

        this.retNeedFamily = CONSTANTS.RETIREMENT_NEED;
        this.retNeedSingle = CONSTANTS.RETIREMENT_NEED_SINGLE_F;

        this.reportDataService.reportData.rptIntro1 = document.getElementById("rptIntro1").innerText;
        this.reportDataService.reportData.rptIntro2 = document.getElementById("rptIntro2").innerText;
        
        //this.reportDataService.reportData.rptIntro2 = document.getElementById("rptIntro2").innerText;
        this.reportDataService.reportData.summaryFootnote = ""; // removed from site - document.getElementById("summaryFootnote").innerText;


        this.reportDataService.reportData.projRetBalance = this.projRetBalance.toString();
        // {{projRetBalance | currency:'USD':'symbol':'1.0-0'}}

        this.reportDataService.reportData.altRetBalance = this.altRetBalance.toString();
        
        this.reportDataService.reportData.cumulativeTaxSavings = this.cumulativeTaxSavings.toString();

        this.reportDataService.reportData.taxSavings = this.taxSavings.toString(); // this.calculationService.taxSavings.toString();

        this.reportDataService.reportData.hsaOOP = this.formData.hsaOOP.toString();
        this.reportDataService.reportData.hsaContrib = this.formData.hsaContrib.toString();
        this.reportDataService.reportData.hsaSpendAlt = this.formData.hsaSpendAlt.toString();
        this.reportDataService.reportData.hsaContribAlt = this.formData.hsaContribAlt.toString();
        this.reportDataService.reportData.retirementAge = this.formData.retirementAge.toString();
        this.reportDataService.reportData.fedTax = this.formData.fedTax.toString();
        this.reportDataService.reportData.stateTax = this.formData.stateTax.toString();
        this.reportDataService.reportData.ror = this.formData.ror.toString();


    }


    initChartData() {

        this.options = null;

        //this.calculationService.initCalc( this.formDataService.getFormData());

        // calculate projection results
        this.calculationService.calcProjResults();
        this.projResults = this.calculationService.projResults;
        this.projRetBalance = this.projResults.retirementBalance;

        // alternate projection results
        // this.calculationService.calcAltProjResults();
        // this.altResults = this.calculationService.altProjResults;
        // this.altRetBalance = this.altResults.retirementBalance;

        this.cumulativeTaxSavings = this.calculationService.taxSavings * this.projResults.year.length;

        var myyears = [];
        var endpoints = [];
        for (var i=0; i < this.projResults.year.length; i++) {
            myyears.push(this.projResults.year[i]);
            if (i==0) {
                
                endpoints.push("Today");
            } else if (i == this.projResults.year.length-1) {
                endpoints.push("Retirement");
                // if (Math.abs(i % 2) == 0) {
                //     myyears.push(this.projResults.year[i]);
                //     endpoints.push("Retirement");
                // } else {
                //     myyears.push(this.projResults.year[i]);
                //     endpoints.push("");
                // }
            } else if (i == this.projResults.year.length-2) {
                // if (Math.abs(i % 2) == 1) {
                //     myyears.push(this.projResults.year[i]);
                //     endpoints.push("Retirement");
                // }  else {
                //     myyears.push(this.projResults.year[i]);
                //     endpoints.push("");
                // }
                endpoints.push("");
            } else {
                
                endpoints.push("");
            }

        }

        // Highcharts chart options
        const that = this;
        this.options = {

            chart: {
                type: 'column',
                height: 300,
                spacingLeft: 20,
                spacingRight: 20,
                spacingTop: 20,
                spacingBottom: 0,
                style: {
                    fontFamily: 'inherit',
                },
              
            },
          
            title: {
                text: '',
                useHTML: true,
                style: {
                    color: '#2f792c',
                    align: 'center',
                        'background-color': '#fff',
                        'text-align': 'center',
                        'font-family': 'inherit',
                        'width': '100%',
                }
            },
            legend: {
                enabled: false,
            },
            // xAxis: {
            //     labels: {  
                    
            //     },
             
            // },

            xAxis: [{
            }],
                // offset: -300,
                // tickWidth: 0,
                // lineWidth: 0,
                // step: 1,
                // // categories: myyears,
                // labels: {
                //     x: 5,
                //     step: 1,
                //     useHTML: true,
                //     style:{
                //     color: 'red'
                //     },
                //     formatter: function () {
                //         return this.value;
                //     }
                // }
                // }, {
                //     linkedTo: 0,
                //     categories: myyears,
                //     // 
                   


                // },
                // {
                //     linkedTo: 0,
             
                //     categories: endpoints,
                //     tickAmount: 1,
                //     labels: {
                //         formatter() {
                //             if(this.isFirst || this.isLast) {
                //                 return this.value
                //             } else {
                //                 return ''
                //             }
                //         }}
                // }],


            yAxis: {
                title: { 
                    text: '<b>Amount</b> (in dollars)<br>',
                    style: {
                        color: '#368727', 'background-color': '#eee', 'padding': '10px 20px'
                    },
                    useHTML: true
                },
                min: 0,
                labels: {
                    formatter: function () {
                        return that.currencyPipe.transform(this.value, 'USD', 'symbol', '1.0-0');
                    }
                },
                reversedStacks: false,
                gridLineColor: '#fff'
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
                formatter: function () {
                    return '<b>' + this.series.name + '</b>: ' + that.currencyPipe.transform(this.point.y, 'USD', 'symbol', '1.0-0');
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    pointWidth: 10,
                },
                column: {
                    // stacking: 'normal',
                    grouping: false,
                    groupPadding: .45
                }
            },
            credits: {
                enabled: false
            },
        };

        // add x-axis
        const xAxis = [];
        xAxis.push({
            labels: { enabled: true, allowOverlap: false },
            title: { 
                text: '<b>Years from Today to Retirement</b>',
                style: {
                    color: '#368727', 
                        'background-color': '#eee', 
                        'text-align': 'center',
                        'padding': '10px 30px'
                },
                useHTML: true
            },
            categories: myyears, //his.altResults.year,
            lineColor: 'transparent',
        });
        // xAxis.push({
        //     // labels: { enabled: true, allowOverlap: false },
        //     title: { 
        //         text: '',
        //         style: {
        //             color: '#368727', 
        //                 'background-color': '#eee', 
        //                 'padding': '2px 120px 2px 120px'
        //         },
        //         useHTML: true
        //     },
        //     categories: endpoints, //his.altResults.year,
        //     lineColor: 'transparent',
        //     tickAmount: 1,
        //     labels: {
        //         enabled: true, allowOverlap: false,
        //         formatter() {
        //             if(this.isFirst || this.isLast) {
        //                 return this.value
        //             } else {
        //                 return ''
        //             }
        //         }
        //     }

        // });

        this.options['xAxis'] = xAxis;

        // const parsedData = this.altResults.map(s => ({
        //     name: s.name,
        //     data: s.data.map(d => [d[0].toString(), d[1]])
        // }));
        
        // add data series
        const series = [];
        series.push({
            name: 'Estimated savings at retirement age',
            data: this.projResults.value,
            color: '#2f792c',
            index: 1,
            legendIndex: 0,
        });
        // series.push({
        //     name: 'Potential balance with changes to savings/spending',
        //     data: this.altResults.value,
        //     color: '#888888',
        //     index: 0,
        //     legendIndex: 1,
        // });

        // const parsedData = initialSeries.map(s => ({
        //     name: s.name,
        //     data: s.data.map(d => [d[0].toString(), d[1]])
        // }));

        
        this.updateFlag = true;
        this.options['series'] = null; //series;
        this.options['series'] = series;
        this.setPDFData();
    }
    
    
    /**
    * Build the options for the retirement age select.
    * Confined by a age param and maxRetAge class property.
    *
    * @param {number} age
    *
    * @memberOf Step1Component
    */
    buildRetirementAgeOptions(age: number) {
        // build retirement age options
        const min: number = age;
        const max: number = this.validationService.maxRetAge;
        this.retireAgeOptions = [];
        for (let i = min; i <= max; i++) {
            this.retireAgeOptions.push(i);
        }

        // update retirement age if selected current age is greater
        const minAge = this.formSummary.controls['retirementAge'].value;
        if (age > minAge) {
            this.formSummary.patchValue({ retirementAge: age });
        }
    }
    /**
       * Build the options for the current age select.
       * Confined by the minAge/maxAge class properties.
       *
       * @memberOf Step1Component
       */
    buildCurrentAgeOptions() {
        const min = this.validationService.minCurrentAge;
        const max = this.validationService.maxCurrentAge;
        for (let i = min; i <= max; i++) {
            this.currentAgeOptions.push(i);
        }
    }


    /**
    * Build federal tax options from array.
    *
    * @memberOf Step2Component
    */
    buildFedTaxOptions() {
        this.fedTaxOptions = this.validationService.fedTaxOptions;
    }

    /**
     * Build state tax options from range.
     *
     * @memberOf Step2Component
     */
    buildStateTaxOptionsXXX() {
        // const min: number = this.validationService.stateTaxMin;
        // const max: number = this.validationService.stateTaxMax;
        // for (let i = min; i <= max; i++) {
        //     this.stateTaxOptions.push(i);
        // }
    }


    buildStateTaxOptions() {
        const min: number = this.validationService.stateTaxMin;
        const max: number = this.validationService.stateTaxMax;
        // for (let i = min; i <= max; i++) {
        //     this.stateTaxOptions.push(i);
        // }


        this.stateTaxOptions = [ 
            {state: 'AL', rate: 4.00},
            {state: 'AK', rate: 0.00},
            {state: 'AZ', rate: 5.60},
            {state: 'AR', rate: 6.50},
            {state: 'CA', rate: 7.25},
            {state: 'CO', rate: 2.90},
            {state: 'CT', rate: 6.35},
            {state: 'DE', rate: 0.00},
            {state: 'DC', rate: 6.00},
            {state: 'FL', rate: 6.00},
            {state: 'GA', rate: 4.00},
            {state: 'HI', rate: 4.00},
            {state: 'ID', rate: 6.00},
            {state: 'IL', rate: 6.25},
            {state: 'IN', rate: 7.00},
            {state: 'IA', rate: 6.00},
            {state: 'KS', rate: 6.50},
            {state: 'KY', rate: 6.00},
            {state: 'LA', rate: 4.45},
            {state: 'ME', rate: 5.50},
            {state: 'MD', rate: 6.00},
            {state: 'MA', rate: 6.25},
            {state: 'MI', rate: 6.00},
            {state: 'MN', rate: 6.875},
            {state: 'MS', rate: 7.00},
            {state: 'MO', rate: 4.225},
            {state: 'MT', rate: 0.00},
            {state: 'NE', rate: 5.50},
            {state: 'NV', rate: 6.85},
            {state: 'NH', rate: 0.00},
            {state: 'NJ', rate: 6.625},
            {state: 'NM', rate: 5.00},
            {state: 'NY', rate: 4.00},
            {state: 'NC', rate: 4.75},
            {state: 'ND', rate: 5.00},
            {state: 'OH', rate: 5.75},
            {state: 'OK', rate: 4.50},
            {state: 'OR', rate: 0.00},
            {state: 'PA', rate: 6.00},
            {state: 'RI', rate: 7.00},
            {state: 'SC', rate: 6.00},
            {state: 'SD', rate: 4.50},
            {state: 'TN', rate: 7.00},
            {state: 'TX', rate: 6.25},
            {state: 'UT', rate: 6.10},
            {state: 'VT', rate: 6.00},
            {state: 'VA', rate: 5.30},
            {state: 'WA', rate: 6.50},
            {state: 'WV', rate: 6.00},
            {state: 'WI', rate: 5.00},
            {state: 'WY', rate: 4.00} ];
    }

    /**
     * Set variables for use in HTML.
     *
     * @memberOf SummaryComponent
     */
    setVariables() {

        // current form data
        const formData = this.formData;

        // single vs family coverage and retirement needs
        if (formData.coverageType === 'S') {
            this.singleCoverage = true;
            this.familyCoverage = false;
        } else {
            this.singleCoverage = false;
            this.familyCoverage = true;
        }

        // retirement need
        this.retirementNeed = this.calculationService.retirementNeed;

        // current HSA contribution amount
        this.hsaContrib = formData.hsaContrib;
        //console.log("this.hsaContrib: " + this.hsaContrib);
        // alternate HSA contrib range
        // between current contribution and IRS max
        this.hsaMaxContrib = Number(this.calculationService.hsaMax);
        this.hsaMinContrib = Number(formData.hsaContrib);

        // alternate HSA spend range
        // must be between $0 and current spend
        // this.hsaMaxSpend = Number(formData.hsaSpend);

        this.hsaMaxSpend =this.validationService.hsaMaxSpend,   
        this.hsaMinSpend = 0;

        // flags for contributing at max and spending at min
        // leads to determination of whether we show alternate scenario
        this.contribAtMax = this.calculationService.contribAtMax;
        this.spendAtMin = this.calculationService.spendAtMin;
        this.showAlternate = this.calculationService.flagShowAlternateSummary;

    }

    /**
     * Init data needed for form.
     *
     * @memberOf SummaryComponent
     */
    initFormData() {
        this.formDefaults = this.formDataService.getDefaults();
        this.formData = this.formDataService.getFormData();
        this.formErrors = this.validationService.validationErrors;
        this.buildForm();
    }

    /**
     * Use FormBuilder to create form.
     * Subscribe to form value changes and validate.
     * Save data to form data service if valid.
     * Calculate values as needed.
     *
     * @memberOf SummaryComponent
     */
    buildForm() {
        // build summary form
        this.formSummary = this.formBuilder.group({
            hsaContribAlt: [this.formData.hsaContribAlt, Validators.required],
            hsaSpendAlt: [this.formData.hsaSpendAlt, Validators.required],
            retirementAge: [this.formData.retirementAge, Validators.required],
            fedTax: [this.formData.fedTax, Validators.required],
            stateTax: [this.formData.stateTax, Validators.required],
            ror: [this.formData.ror, Validators.required],

        });

        // set current form data in formDataService
        this.formDataService.currentFormGroup = this.formSummary;

        // subscribe to summary form changes
        this.formSubscription = this.formSummary.valueChanges.subscribe(data => {
            // this.formErrors = this.validationService.validateFormData(data, this.formSummary);
            // if (this.formSummary.valid) {
            //     this.formDataService.setFormData(data);
            // }
            // this.calculationService.calcHSAMax(data.currentAge, data.coverageType);
            // this.calculationService.calcDefaultHSAContribSpend('force');
            // this.calculationService.calcRetirementNeed(data.coverageType);
            // this.calculationService.calcYearsUntilRetirement(data.currentAge, data.retirementAge);
        });
    }

    /**
     * Init alternate HSA contrib and spend sliders.
     *
     * @memberOf SummaryComponent
     */
    initSliders() {
        const that = this;

        // alternate HSA contribution slider
        this.sliderHSAContribAlt = new Slider('#hsaContribAlt', {
            min: 0, // this.hsaMinContrib,
            max: this.hsaMaxContrib,
            step: CONSTANTS.SLIDER_STEP,
            handle: 'square',
            labelledby: "hsaContriblabelSum",
            value: this.formDataService.getValue('hsaContrib'),
            tooltip: 'always',
            // formatter: function (value) {
            //     return that.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
            // }
            formatter: function (value) {
                return that.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0') + "/yr\n(" + that.currencyPipe.transform(value/12, 'USD', 'symbol', '1.0-0') + "/mo)";
            }

        });

        // alternate HSA spend slider
        this.sliderHSASpendAlt = new Slider('#hsaSpendAlt', {
            // min: this.hsaMinSpend,
            // max: this.hsaMaxSpend,
            min: this.validationService.hsaMinSpend,
            max: this.validationService.hsaMaxSpend,    
            step: CONSTANTS.SLIDER_STEP,
            handle: 'square',
            labelledby: "hsaSpendlabelSum",
            value: this.formDataService.getValue('hsaSpend'),
            tooltip: 'always',
            // formatter: function (value) {
            //     return that.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
            // }
            formatter: function (value) {
                return that.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0') + "/yr\n(" + that.currencyPipe.transform(value/12, 'USD', 'symbol', '1.0-0') + "/mo)";
            }
        });

        // alternate HSA contrib slider changes
        // set values manually (not picked up by valueChanges subscription)
        // recalculate alternate scenario
                                        // this.sliderHSAContribAlt.on('change', function (e) {
                                        //     // contribution value from slider change event
                                        //     const contribVal = e.newValue;

                                        //     // set value of contrib slider
                                        //     that.sliderHSAContribAlt.setValue(contribVal, true, true);
                                        //     that.formDataService.setValue('hsaContribAlt', contribVal);

                                        //     // calculate
                                        //     that.recalculateChart();

                                        //     // set alternate spender saver flag
                                        //     that.calculationService.setAltSpenderSaverFlag(contribVal, that.sliderHSASpendAlt.getValue());

                                        //     // set flag to show alternate on results page
                                        //     that.calculationService.setShowAlternateResultsFlag();

                                        //     // calculate tax savings for alt contribs
                                        //     that.calculationService.calcTaxSavingsAlt();
                                        // });
                              

        this.sliderHSAContribAlt.on('change', function (e) {
            // contribution value from slider change event
            const contribVal = e.newValue;
            // spend value from other slider method
            const spendVal = that.sliderHSASpendAlt.getValue();
            // set maximum spend value
            //const maxHSASpend = that.hsaContribChange(contribVal);
            // set value of contrib slider
            that.sliderHSAContribAlt.setValue(contribVal, true, true);
            that.formDataService.setValue('hsaContrib', contribVal);
            // set new max for spend slider equal to value of contrib slider
            //that.sliderHSASpendAlt.setAttribute('max', maxHSASpend);
            // if we are spending more than contributing, correct that
            let spendUse = 0;
            if (spendVal > contribVal) {
                spendUse = contribVal;
            } else {
                spendUse = spendVal;
            }

            spendUse = spendUse;
            //("spendUse: " + spendUse + ", contribVal: " + contribVal);
            if (contribVal <= spendUse) {
                spendUse = 0; // remove this if the contributions >= spending and you want the spending to equal contribs instead
                that.sliderHSASpendAlt.setValue(spendUse)
                that.sliderHSASpendAlt.setAttribute('value', spendUse);
                that.formDataService.setValue('hsaSpend', spendUse);
                that.sliderHSASpendAlt.refresh();
            }

            ////// that.sliderHSASpend.setValue(spendUse);
            ////// that.sliderHSASpend.setAttribute('value', spendUse);
            ////// that.formDataService.setValue('hsaSpend', spendUse);
            ////// refresh spend slider with new max and possible new value
            ////// that.sliderHSASpend.refresh();
            ////// calculate alternate values
            that.calculationService.calcAlternateContrib(contribVal, 'force');
            ////// that.calculationService.calcAlternateSpend(spendUse, 'force');
            // set calc flag for max contributor
            that.calculationService.setMaxContributor(contribVal);
            // set calc flag for min spender
            ////// that.calculationService.setMinSpender(spendUse);
            // set calc flag for showing alt scenario
            that.calculationService.setShowAlternateSummaryFlag();
            that.calculationService.setShowAlternateResultsFlag();
            // calc tax savings
            that.calculationService.calcTaxSavings();
            that.calculationService.calcTaxSavingsAlt();

            that.recalculateChart();
            
            // set spender/saver flag in calc service
           //////  that.calculationService.setSpenderSaverFlag(contribVal, spendUse);
        });
















        
        // alternate HSA spend slider changes
        // set values manually (not picked up by valueChanges subscription)
        // recalculate alternate scenario
        this.sliderHSASpendAlt.on('change', function (e) {
            // spend value from slider change event
            const spendVal = e.newValue;

            // set value of spend slider
            that.sliderHSASpendAlt.setValue(spendVal, true, true);
            that.formDataService.setValue('hsaSpend', spendVal);

            // calculate
            that.recalculateChart();

            // set alternate spender saver flag
            that.calculationService.setAltSpenderSaverFlag(that.sliderHSAContribAlt.getValue(), spendVal);

            // set flag to show alternate on results page
            that.calculationService.setShowAlternateResultsFlag();
            
        });

           // ROR Slider
        // create slider instance
        this.sliderROR = new Slider('#ror', {
            min: this.validationService.minROR,
            max: this.validationService.maxROR,
            step: 1,
            handle: 'round',
            labelledby: "rorlabelSum",
            value: this.formDataService.getValue('ror'),
            tooltip: 'always',
            formatter: function(value) {
                return value + '%';
            }
        });

        // slider change event - set value manually
        // this does not get picked up by valueChanges subscription
        this.sliderROR.on('change', function(e) {
            that.sliderROR.setValue(e.newValue);
            that.formDataService.setValue('ror', e.newValue);
            //console.log("ROR: " +e.newValue );
            that.calc();
        });
       
    }



        /**
     * Handle HSA contrib slider changes.
     * Set max HSA spend value to the HSA contrib value
     * to make sure user does not spend more than they contribute.
     *
     * @param {number} value
     *
     * @memberOf Step3Component
    
        hsaContribChange(value: number) {
            const oop = Number(this.formDataService.getValue('hsaOOP'));
            this.validationService.hsaMaxSpend = Math.min(value, oop);
            return this.validationService.hsaMaxSpend;
        }

 */

    calc() {

        //console.log("calc()");
        // calculate
        this.recalculateChart();


        // set flag to show alternate on results page
        this.calculationService.setShowAlternateResultsFlag();

        // calculate tax savings for alt contribs
        this.calculationService.calcTaxSavingsAlt();
    }


    /**
     * Toggle Highcharts chart series.
     * Show/Hide series in chart on legend click event.
     *
     * @param {string} series
     *
     * @memberOf SummaryComponent
     */
    toggleSeries(series: string) {
        switch (series) {
            case 'proj':
                const projSeries = this.chart.series[1];
                if (this.projSeriesVisible) {
                    projSeries.hide();
                    this.projSeriesVisible = false;
                } else {
                    projSeries.show();
                    this.projSeriesVisible = true;
                }
                break;
            case 'alt':
                const altSeries = this.chart.series[0];
                if (this.altSeriesVisible) {
                    altSeries.hide();
                    this.altSeriesVisible = false;
                } else {
                    altSeries.show();
                    this.altSeriesVisible = true;
                }
                break;
        }
    }

    /**
     * Summary item content/values/actions.
     *
     * @memberOf SummaryComponent
     */
    initYourSummary() {
        const formData = this.formData;

        this.summaryItems = [
            {
                // title: 'You plan to contribute the following amount to your HSA annually:',
                title: 'Annual HSA contribution',
                value: formData.hsaContrib,
                link: '',
                modifier: 'currency',
            },
            {
                // title: 'Out-of-pocket costs for qualified medical expenses:',
                title: 'Qualified out-of-pocket medical expenses',
                value: formData.hsaOOP,
                link: '',
                modifier: 'currency',
            },
            {
                title: 'Annual tax savings',
                value: this.calculationService.taxSavings,
                link: '',
                modifier: 'currency',
            }
        ];

        this.assumptionItems = [
            {
                title: 'Retirement age:',
                value: formData.retirementAge,
                link: 'step1',
                modifier: '',
            },
            {
                title: 'Current tax rate:',
                value: Number(formData.stateTax) + Number(formData.fedTax),
                link: 'step2',
                modifier: 'percent',
            },
            {
                title: 'Investment rate of return:',
                value: formData.ror,
                link: 'step2',
                modifier: 'percent',
            }
        ];
    }

    getstarted() {
        // this.router.navigate(['/'])
        // .then(() => {
        //   window.location.reload();
        // });
        this.router.navigate(['https://www.fidelity.com/go/hsa/why-hsa?ccsource=calculator_web_redirect_2023']);
        // .then(() => {
        //   window.location.reload();
        // });
        
    }

    // setStateTax(e) {

    //     this.formDataService.setValue('stateTax', e.rate);
    //     this.formSummary.patchValue({ stateTax: e.rate });
    //     this.formSummary.patchValue({ state: e.state });

    //     console.log("stateTax: " + e.rate + ", " + this.formDataService.getValue('stateTax'));
    //     // this.calc();
    //     // this.recalculateChart();

    // }

    setRetAge(e) {
        this.formDataService.setValue('retirementAge', parseInt(e));
        this.recalculateChart();
    }


    setStateTax(e) {
      
       // this.formSummary.setValue({ stateTax: e.rate })
        this.formDataService.setValue('stateTax', e.rate);
        this.formDataService.setValue('state', e.state);
        //this.formSummary.patchValue({ stateTax: e.rate });
        //this.formSummary.patchValue({ state: e.state });
        this.formSummary.controls['stateTax'].setValue(e.rate);

        this.calculationService.calcTaxSavings();
        this.cumulativeTaxSavings = this.calculationService.taxSavings * this.calculationService.projResults.year.length;
        this.setPDFData();
    }
    setFedTax(e) {
      
        this.formDataService.setValue('fedTax', e);
        this.formSummary.patchValue({ fedTax: e });
        this.calculationService.calcTaxSavings();
        this.cumulativeTaxSavings = this.calculationService.taxSavings  * this.calculationService.projResults.year.length;;
        this.setPDFData();
    }

    /**
     * Calculate future value payment based on user inputs.
     *
     * @param {number} contrib
     * @param {number} spend
     * @returns
     *
     * @memberOf SummaryComponent
     */
    calcPayment(contrib: number, spend: number) {
        return contrib - spend;
    }
}
