<div class="page-footer">

    <!-- <div class="col-sm-12">
        <app-navigation></app-navigation>
    </div>  -->

    <div class="row" *ngIf="pageData.showDisclosures">
        <div class="col-sm-12">
            <div class="footer-disclosures">

                <p>
                    Calculators and related tools are provided by SS&C Retirement Solutions, LLC, a 
                    party unaffiliated with National Financial Services LLC, or Fidelity Brokerage 
                    Services LLC (“Fidelity”), or your firm. Except for Fidelity providing the Retiree 
                    healthcare numbers to be used in the calculator, neither Fidelity nor your firm has 
                    been involved in the preparation or the content supplied by SS&C and does not guarantee 
                    or assume any responsibility for its content. The information contained herein is 
                    general in nature, is provided for informational and educational purposes only, and 
                    should not be considered legal or tax advice. The information is not tailored to the 
                    investment needs of any specific investor.
                </p>
                <p>
                    Federal and State tax bracket options are static and independent of one another; State tax 
                    rate is based on median income.  The tax rates are intended for guidance purposes only and 
                    you should check with your tax advisor for more details.
                </p>
                <p>
                    1093860.3.0
                </p>

            </div>
        </div>
    </div>
</div>



<ng-template #modalSIPC>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Fidelity</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideSIPC()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center">
            <p class="important font-bold mb-0">Important Notice</p>
            <p class="subtitle">You're continuing to another website</p>
        </div>
        <p>
            You're continuing to another website that Fidelity doesn't own or operate.
            It's owner is solely responsible for the website's content offerings and level of security,
            so please refer to the website's posted privacy policy and terms of use.
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-blue" (click)="hideSIPC()">Cancel</button>
        <button class="btn btn-blue" (click)="goToSIPC()">Continue</button>
    </div>
</ng-template>